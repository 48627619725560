.work__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.work {
  background: var(--color-bg-variant);
  padding: 1.5rem 2.5rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.work:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
  cursor: default;
}

.work__container h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.work__list {
  padding: 1rem;
}

.work__list li {
  display: flex;
  gap: 1rem;
  margin-bottom: 0.8rem;
}

.work__list-icon {
  color-scheme: var(--color-primary);
  margin-top: 2px;
}

.work__list p {
  font-size: 0.9rem;
}

small {
  font-size: 0.9rem;
}

.workplace {
  margin-left: auto;
  margin-right: 0;
}

/* MEDIA QUERIES (TABLETS) */

@media screen and (max-width: 1024px) {
  .work__container {
    grid-template-columns: 1fr;
  }

  .work {
    height: auto;
    width: 80%;
    margin: 0 auto;
  }
}

/* MEDIA QUERIES (PHONES) */

@media screen and (max-width: 600px) {
  .work__container {
    gap: 2rem;
    width: 100%;
  }

  .work__container h3 {
    margin-bottom: 0.5rem;
  }

  .work {
    width: 100%;
    padding: 1rem 0rem;
  }

  .work__list p {
    font-size: 0.7rem;
  }

  small {
    font-size: 0.7rem;
  }
}
